import { useCallback } from "react";
import { useNavigate, useSearch } from "@tanstack/react-router";
import debounce from "debounce";

export const useSessionSearchParamsUpdater = (initialPath: string) => {
  const previousSearch = useSearch({
    from: "/_authenticated/companies/$companyId/_dashboard/sessions",
  });

  const navigate = useNavigate({ from: initialPath });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpdateFilter = useCallback(
    debounce((filterValue: string) => {
      // Initialize search with the previous search params and new filter value
      const nextSearch = {
        ...previousSearch,
        filter: filterValue,
      };

      // If filter changed reset the offset and limit
      if (previousSearch.filter !== filterValue) {
        nextSearch.offset = 0;
        nextSearch.limit = 10;
      }

      navigate({
        search: nextSearch as any,
      });
    }, 500),
    [navigate, previousSearch]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpdateDateRange = useCallback(
    // Debounces here prevents full re-rendering of the page, so should be left as-is.
    debounce(
      (dateRange: {
        dateFrom?: Date | undefined;
        dateTo?: Date | undefined;
      }) => {
        navigate({
          search: {
            ...(previousSearch as any),
            dateFrom: dateRange?.dateFrom?.toISOString() ?? undefined,
            dateTo: dateRange?.dateTo?.toISOString() ?? undefined,
          },
        });
      },
      0
    ),
    [navigate, previousSearch]
  );

  return {
    updateSearchFilter: handleUpdateFilter,
    updateDateRange: handleUpdateDateRange,
  };
};
