import {
  BasePaymentPlan,
  CreateCompany,
  createCompanySchema,
} from "@elton/types";
import {
  SheetClose,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@elton/ui/core";

import { LoadingButton } from "@/components/buttons/loading-button";
import { InputField } from "@/components/fields/input-field";
import { SelectField } from "@/components/fields/select-field";

import { useCompanyPartialForm } from "./use-company-partial-form";

import { T, useT } from "@transifex/react";
import { useRole } from "@/context/role-context/use-role";

export type FormRenderState =
  | "KEY"
  | "INVOICING"
  | "ADDRESS"
  | "PAYMENT"
  | "NOTE";

interface IProps {
  disabled?: boolean;
  data: CreateCompany;
  paymentPlans: BasePaymentPlan[];
  onUpdate: (data: CreateCompany) => void;
  state: FormRenderState | null;
  loading?: boolean;
}

/**
 * This form component is different from the standard CompanyForm.
 * Instead of displaying the entire form, it conditionally renders specific attributes.
 * This approach is beneficial for making partial updates to the company entity, such as on the company details page.
 */
export const CompanyPartialForm: React.FC<IProps> = ({
  disabled,
  data,
  paymentPlans,
  state,
  onUpdate,
  loading = false,
}) => {
  const t = useT();
  const { isAdmin } = useRole();
  const form = useCompanyPartialForm({
    data,
    onSubmit: onUpdate,
  });

  const handleFormSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    void form.handleSubmit();
  };

  return (
    <form.Subscribe
      children={() => (
        <>
          <SheetHeader>
            <SheetTitle>
              <T _str="Update company" />
            </SheetTitle>
            <SheetDescription>
              <T _str="Update company - Click the button when you're done." />
            </SheetDescription>
          </SheetHeader>
          <form className="grid grid-cols-12 gap-6 text-primary">
            {state === "KEY" && (
              <>
                <form.Field
                  name="name"
                  validators={{
                    onBlur: createCompanySchema.shape.name,
                  }}
                  children={(field) => (
                    <InputField
                      field={field}
                      className="col-span-full"
                      label={t("Company Name")}
                      placeholder={t("My company")}
                      disabled={disabled}
                    />
                  )}
                />
                <form.Field
                  name="organizationNumber"
                  validators={{
                    onBlur: createCompanySchema.shape.organizationNumber,
                  }}
                  children={(field) => (
                    <InputField
                      field={field}
                      className="col-span-full"
                      label={t("Organisation Number")}
                      placeholder="xxxxxxxxx"
                      disabled={disabled}
                    />
                  )}
                />
                <form.Field
                  name="contactName"
                  validators={{
                    onBlur: createCompanySchema.shape.contactName,
                  }}
                  children={(field) => (
                    <InputField
                      field={field}
                      className="col-span-full"
                      label={t("Contact Name")}
                      placeholder="John Smith"
                      disabled={disabled}
                    />
                  )}
                />
                <form.Field
                  name="contactPhone"
                  validators={{
                    onBlur: createCompanySchema.shape.contactPhone,
                  }}
                  children={(field) => (
                    <InputField
                      field={field}
                      className="col-span-full"
                      label={t("Contact Phone")}
                      placeholder="+47xxxxxxxx"
                      disabled={disabled}
                    />
                  )}
                />
                <form.Field
                  name="contactEmail"
                  validators={{
                    onBlur: createCompanySchema.shape.contactEmail,
                  }}
                  children={(field) => (
                    <InputField
                      field={field}
                      className="col-span-full"
                      label={t("Contact Email")}
                      placeholder="contact@mycompany.com"
                      disabled={disabled}
                    />
                  )}
                />
              </>
            )}
            {state == "INVOICING" && (
              <>
                <form.Field
                  name="invoiceEmail"
                  validators={{
                    onBlur: createCompanySchema.shape.invoiceEmail,
                  }}
                  children={(field) => (
                    <InputField
                      field={field}
                      className="col-span-full"
                      label={t("Invoice email")}
                      placeholder="invoice@example.com"
                      disabled={!isAdmin}
                    />
                  )}
                />
                <form.Field
                  name="invoicePaymentTime"
                  validators={{
                    onBlur: createCompanySchema.shape.invoicePaymentTime,
                  }}
                  children={(field) => (
                    <InputField
                      type="number"
                      field={field}
                      className="col-span-full"
                      label={t("Payment time")}
                      placeholder=""
                      disabled={!isAdmin}
                    />
                  )}
                />
                <form.Field
                  name="externalInvoiceId"
                  validators={{
                    onBlur: createCompanySchema.shape.externalInvoiceId,
                  }}
                  children={(field) => (
                    <InputField
                      field={field}
                      className="col-span-full"
                      label={t("Invoice ID (External)")}
                      placeholder=""
                      disabled={!isAdmin}
                    />
                  )}
                />
              </>
            )}
            {state == "ADDRESS" && (
              <>
                <form.Field
                  name="address"
                  validators={{
                    onBlur: createCompanySchema.shape.address,
                  }}
                  children={(field) => (
                    <InputField
                      field={field}
                      className="col-span-full"
                      label={t("Street Address")}
                      placeholder="Edvard Storms gate 2"
                      disabled={disabled}
                    />
                  )}
                />
                <form.Field
                  name="postalCode"
                  validators={{
                    onBlur: createCompanySchema.shape.postalCode,
                  }}
                  children={(field) => (
                    <InputField
                      field={field}
                      className="col-span-full md:col-span-6"
                      label={t("Postal Code")}
                      placeholder="0166"
                      disabled={disabled}
                    />
                  )}
                />
                <form.Field
                  name="city"
                  validators={{
                    onBlur: createCompanySchema.shape.city,
                  }}
                  children={(field) => (
                    <InputField
                      field={field}
                      className="col-span-full md:col-span-6"
                      label={t("City")}
                      placeholder="Oslo"
                      disabled={disabled}
                    />
                  )}
                />
                <form.Field
                  name="country"
                  validators={{
                    onBlur: createCompanySchema.shape.country,
                  }}
                  children={(field) => (
                    <InputField
                      field={field}
                      className="col-span-full"
                      label={t("Country")}
                      placeholder="Norway"
                      disabled={disabled}
                    />
                  )}
                />
              </>
            )}
            {state === "PAYMENT" && (
              <>
                <form.Field
                  name="paymentPlanId"
                  validators={{
                    onChange: createCompanySchema.shape.paymentPlanId,
                  }}
                  children={(field) => (
                    <SelectField
                      data={paymentPlans}
                      keyExtractor={{ key: "id", value: "name" }}
                      field={field}
                      label={t("Payment plan")}
                      placeholder={t("Select a payment plan")}
                      className="col-span-full"
                      disabled={!isAdmin}
                    />
                  )}
                />
                <form.Field
                  name="monthlyCost"
                  validators={{
                    onChange: createCompanySchema.shape.monthlyCost,
                  }}
                  children={(field) => (
                    <InputField
                      field={field}
                      type="number"
                      className="col-span-full"
                      label={t("Monthly cost")}
                      placeholder="0"
                      onChange={(e) =>
                        field.handleChange(Number(e.target.value))
                      }
                      disabled={!isAdmin}
                    />
                  )}
                />
                <form.Field
                  name="discountPercentage"
                  validators={{
                    onChange: createCompanySchema.shape.discountPercentage,
                  }}
                  children={(field) => (
                    <InputField
                      field={field}
                      type="number"
                      className="col-span-full"
                      label={t("Discount percentage")}
                      placeholder="0%"
                      onChange={(e) =>
                        field.handleChange(Number(e.target.value))
                      }
                      disabled={!isAdmin}
                    />
                  )}
                />
              </>
            )}
            {state === "NOTE" && (
              <>
                <form.Field
                  name="internalNote"
                  validators={{
                    onBlur: createCompanySchema.shape.internalNote,
                  }}
                  children={(field) => (
                    <InputField
                      field={field}
                      className="col-span-full"
                      label={t("Note")}
                      placeholder=""
                      disabled={!isAdmin}
                    />
                  )}
                />
              </>
            )}
            <SheetFooter className="col-span-full">
              <SheetClose asChild>
                <LoadingButton
                  loading={loading}
                  onClick={handleFormSubmit}
                  type="submit"
                >
                  <T _str="Save" />
                </LoadingButton>
              </SheetClose>
            </SheetFooter>
          </form>
        </>
      )}
    />
  );
};
