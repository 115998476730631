import { CreateExport, createExportSchema } from "@elton/types";
import {
  Calendar,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@elton/ui/core";
import { cn } from "@elton/ui/lib/utils";

import { ExportDropdown } from "@/components/dropdowns/export-dropdown/export-dropdown";
import { DateField } from "@/components/fields/date-field";

import DateRangePresetList from "./date-range-preset-list";
import { useDateRangePresets } from "./use-date-range-presets";
import { useExportForm } from "./use-export-form";

import { useT } from "@transifex/react";

interface IProps {
  data: CreateExport;
  loading?: boolean;
  onCreateExport: (exp: CreateExport, format: "csv" | "xlsx") => void;
  onChangeDate: (exp: CreateExport) => void;
}

export const ExportForm: React.FC<IProps> = ({
  data,
  onCreateExport,
  onChangeDate,
}) => {
  const t = useT();

  const dateRangePresets = useDateRangePresets();

  const form = useExportForm({
    data,
    onSubmit: () => {}, // Handle submits manually
  });

  const handleValueChange = (
    key: "dateFrom" | "dateTo",
    value: Date | undefined
  ) => {
    form.setFieldValue(key, value);
    onChangeDate({
      dateFrom: form.state.values.dateFrom,
      dateTo: form.state.values.dateTo,
      [key]: value,
    });
  };

  return (
    <form.Subscribe
      children={(state) => (
        <>
          <Popover>
            <form
              className={cn("flex items-end gap-2", "text-primary")}
              tabIndex={-1}
            >
              <PopoverTrigger
                className="flex flex-row w-full gap-2"
                tabIndex={-1}
              >
                <form.Field
                  name="dateFrom"
                  validators={{
                    onChange: createExportSchema.shape.dateFrom,
                  }}
                  children={(field) => (
                    <>
                      <DateField
                        className="xl:w-[200px]"
                        tabIndex={0}
                        field={field}
                        label=""
                        placeholder={t("Start date")}
                        required={true}
                        onClickReset={(e) => {
                          e.preventDefault();
                          handleValueChange("dateFrom", undefined);
                        }}
                      />
                    </>
                  )}
                />
                <form.Field
                  name="dateTo"
                  validators={{
                    onChange: createExportSchema.shape.dateFrom,
                  }}
                  children={(field) => (
                    <>
                      <DateField
                        tabIndex={0}
                        className="mb-0 xl:w-[200px]"
                        field={field}
                        label=""
                        placeholder={t("End date")}
                        required={true}
                        onClickReset={(e) => {
                          e.preventDefault();
                          handleValueChange("dateTo", undefined);
                        }}
                      />
                    </>
                  )}
                />
              </PopoverTrigger>
              <ExportDropdown
                onExport={(format) => {
                  onCreateExport(state.values, format);
                }}
                disabled={!(state.values.dateFrom && state.values.dateTo)}
              />
            </form>
            <PopoverContent className="w-auto p-0 flex" align="start">
              <Calendar
                initialFocus
                mode="range"
                defaultMonth={
                  state.values.dateFrom
                    ? new Date(state.values.dateFrom)
                    : new Date()
                }
                selected={{
                  from: state.values.dateFrom
                    ? new Date(state.values.dateFrom)
                    : undefined,
                  to: state.values.dateTo
                    ? new Date(state.values.dateTo)
                    : undefined,
                }}
                onSelect={(range) => {
                  if (range?.from) {
                    handleValueChange("dateFrom", range.from);
                  }
                  if (range?.to) {
                    handleValueChange("dateTo", range.to);
                  }
                }}
                numberOfMonths={2}
              />
              <div className="border p-4 w-48">
                <DateRangePresetList
                  presets={dateRangePresets}
                  onSelectPreset={(preset) => {
                    handleValueChange("dateFrom", preset.dateFrom);
                    handleValueChange("dateTo", preset.dateTo);
                  }}
                />
              </div>
            </PopoverContent>
          </Popover>
        </>
      )}
    />
  );
};
